<template>
  <div class="ma-10">
    <v-alert type="error">Es ist ein Fehler bei der Anmeldung aufgetreten!</v-alert>

    <v-footer>
      <TheSupportInfo />
    </v-footer>
  </div>
</template>
<script>
import TheSupportInfo from '@/components/TheSupportInfo.vue';
export default {
  components: {
    TheSupportInfo
  }
};
</script>
